import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import theme from './theme'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(() => ({
  box: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'column',
    color: theme.palette.common.white,
    height: 659,
    padding: theme.spacing(2, 3, 3, 9),
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundImage: `url(img/costumersBG.jpg)`,
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2, 3),
      height: 659,
    },
    [theme.breakpoints.down('sm')]: {
      backgroundImage: `url(img/costumersBG_m.jpg)`,
      padding: theme.spacing(2, 3),
      height: 800,
    },
  },
  images: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    alignItems: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(1.5),
    },
  },
  image: {
    height: 397,
    width: 262,
    marginRight: theme.spacing(9),
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    [theme.breakpoints.down('sm')]: {
      height: 214,
      width: 142,
      marginRight: theme.spacing(3),
    },
  },
  content: {
    marginTop: theme.spacing(4),
    paddingLeft: theme.spacing(3, 0),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      flexWrap: 'wrap',
      alignItems: 'left',
      padding: 0,
    },
  },
  text: {
    fontFamily: 'Petita',
    fontSize: 14,
    width: 338,
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100vw',
      margin: theme.spacing(1),
      fontSize: 12,
      alignSelf: 'flex-start',
    },
  },
  logos: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      alignSelf: 'flex-start',
    },
  },
  logo: {
    margin: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(1),
      maxHeight: 53,
    },
  },
}))

const CostumerSection = () => {
  const classes = useStyles()
  return (
    <Box className={classes.box}>
      <Box className={classes.images}>
        <Box
          className={classes.image}
          style={{
            backgroundImage: `url(img/run.jpg)`,
          }}
        ></Box>
        <Box
          className={classes.image}
          style={{
            backgroundImage: `url(img/stand.jpg)`,
          }}
        ></Box>
      </Box>

      <Box className={classes.content}>
        <Box className={classes.text}>
          <Typography variant='body2'>
            Wölfsblade is a proud supplier of performance gear into the British
            Army. Current and former customers include…
          </Typography>
        </Box>
        <Box className={classes.logos}>
          <img
            className={classes.logo}
            src='img/logos/ASL.png'
            alt='ASL logo'
            style={{ height: 93 }}
          />
          <img
            className={classes.logo}
            src='img/logos/RAPTC.png'
            alt='RAPTC logo'
            style={{ height: 86 }}
          />
          <img
            className={classes.logo}
            src='img/logos/300Club.png'
            alt='300Club logo'
            style={{ height: 81 }}
          />
          <img
            className={classes.logo}
            src='img/logos/Battleback.png'
            alt='Battleback logo'
            style={{ height: 97 }}
          />
          <img
            className={classes.logo}
            src='img/logos/ArmyHealth.png'
            alt='ArmyHealth logo'
            style={{ height: 58 }}
          />
          <img
            className={classes.logo}
            src='img/logos/42InfantryBrigade.png'
            alt='42 Infantry Brigade logo'
            style={{ height: 86 }}
          />
          <img
            className={classes.logo}
            src='img/logos/RTR.png'
            alt='RTR logo'
            style={{ height: 97 }}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default CostumerSection
