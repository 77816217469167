import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import theme from './theme'

import Input from '@material-ui/core/Input'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'

const useStyles = makeStyles(() => ({
  mainBox: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
  root: {
    border: '1px solid #707070',
    backgroundColor: 'white',
    color: '#796D64',
    textTransform: 'uppercase',
    padding: theme.spacing(0, 1),
    margin: theme.spacing(1, 0),
    fontSize: 16,
    height: 44,
    width: 620,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    '& ::placeholder': {
      textTransform: 'uppercase',
      color: '#796D64',
      opacity: 1,
      fontSize: 16,
    },
  },
  submit: {
    height: 44,
    width: 184,
    borderRadius: 22,
    backgroundColor: '#796D64',
    color: theme.palette.common.white,
    margin: theme.spacing(1, 0),
    boxShadow: 'none',
    [theme.breakpoints.down('sm')]: {
      alignSelf: 'center',
    },
  },
}))

export default function CustomizedInputs() {
  const classes = useStyles()
  const [name, setName] = React.useState('')
  const [email, setEmail] = React.useState('')
  const [telephone, setTelephone] = React.useState('')
  const [message, setMessage] = React.useState('')

  return (
    <Box className={classes.mainBox}>
      <Input
        classes={classes}
        autoComplete='fname'
        name='name'
        fullWidth
        id='name'
        placeholder='Name'
        disableUnderline={true}
        onChange={(e) => setName(e.target.value)}
      />
      <Input
        classes={classes}
        autoComplete='ftelephone'
        name='telephone'
        fullWidth
        type='text'
        id='telephone'
        placeholder='Telephone Number'
        disableUnderline={true}
        onChange={(e) => setTelephone(e.target.value)}
      />
      <Input
        classes={classes}
        autoComplete='femail'
        name='email'
        fullWidth
        type='email'
        id='email'
        placeholder='Email address'
        disableUnderline={true}
        onChange={(e) => setEmail(e.target.value)}
      />
      <Input
        classes={classes}
        style={{ height: 220 }}
        name='message'
        fullWidth
        multiline={true}
        id='message'
        placeholder='Message'
        disableUnderline={true}
        rowsMax={10}
        rows={10}
        onChange={(e) => setMessage(e.target.value)}
      />
      <a
        href={`mailto:contact@wolfsblade.com?subject=Contact from the website &body=${message} %0D%0AName:${name}%0D%0AEmail:${email}%0D%0ATelephone:${telephone}`}
        style={{
          textDecoration: 'none',
        }}
      >
        <Button
          type='submit'
          fullWidth
          variant='contained'
          color='primary'
          className={classes.submit}
        >
          Send
        </Button>
      </a>
    </Box>
  )
}
