import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import theme from './theme'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import CustomizedInputs from './CustomizedInputs'

const useStyles = makeStyles(() => ({
  box: {
    padding: theme.spacing(4, 9),
    background: '#E0DBD6',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      padding: theme.spacing(2, 3),
    },
  },
  gridContainer: {
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  gridForm: {
    [theme.breakpoints.down('sm')]: {
      minWidth: '100%',
    },
  },
  right: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
  rightBox: {
    margin: theme.spacing(3, 0),
    letterSpacing: 0.5,
    [theme.breakpoints.down('sm')]: {
      width: 236,
      margin: theme.spacing(3, 0, 0, 0),
    },
  },
  rightText: {
    textAlign: 'right',
    fontFamily: ['HelveticaNeue'],
    fontSize: '12px',
    letterSpacing: 0.5,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      fontSize: '10px',
      lineHeight: '14px',
      marginBottom: 10,
    },
  },
  image: {
    height: 400,
    [theme.breakpoints.down('sm')]: {
      height: 213,
      alignSelf: 'center',
    },
  },
}))

function ContactSection() {
  const classes = useStyles()
  return (
    <Box className={classes.box}>
      <Grid container spacing={3} className={classes.gridContainer}>
        <Grid item sm={12} md={6} className={classes.gridForm}>
          <Box className={classes.form}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <CustomizedInputs></CustomizedInputs>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid className={classes.right} item sm={12} md={6}>
          <img
            src='img/backofcamera.jpg'
            alt='Back Of Camera'
            className={classes.image}
          />
          <Box className={classes.rightBox}>
            <Typography className={classes.rightText}>
              Wölfsblade have offices in the United Kingdom, Denmark and Dubai
              and ship worldwide
            </Typography>
            <Typography className={classes.rightText}>
              British MOD Supplier Code: E9898 Site Code: 00
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}
export default ContactSection
