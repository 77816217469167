import React from 'react'
import AppBar from '@material-ui/core/AppBar'
import CssBaseline from '@material-ui/core/CssBaseline'

import { ThemeProvider } from '@material-ui/core/styles'
import theme from './theme'

import clsx from 'clsx'

import { makeStyles } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Container from '@material-ui/core/Container'
import Divider from '@material-ui/core/Divider'
import Box from '@material-ui/core/Box'

import MenuIcon from '@material-ui/icons/Menu'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'

import { Link } from 'react-scroll'

import LogoOffwhite from './svgs/logo.svg'
import LogoText from './svgs/logo-text.svg'

import HeroSection from './HeroSection'
import TextBox from './TextBox'
import CostumerSection from './CostumerSection'
import ContactSection from './ContactSection'

const drawerWidth = '100%'

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    margin: 0,
    transform: 'scale(1.8)',
  },
  title: {
    flexGrow: 1,
  },
  boxAppBarButton: {
    padding: theme.spacing(0, 3),
  },
  appBarButton: {
    fontSize: 22,
    letterSpacing: 0.5,
    borderRadius: 0,
    border: 0,
    '&:hover': {
      background: theme.palette.common.red,
      borderRadius: 0,
      border: 0,
      color: theme.palette.common.white,
    },
  },
  hide: {
    display: 'none',
  },
  toolBar: {
    [theme.breakpoints.down('md')]: {
      maxHeight:59
    },
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    background: '#F1EEEC',
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  listItemsText: {
    textAlign: 'center',
    textTransform: 'uppercase',
    color: theme.palette.common.black,
    fontSize: 38,
    fontWeight: 'bold',
    fontFamily: ['HelveticaNeue'],
    margin: theme.spacing(5, 0),
  },
  divider: {
    height: 10,
    background: '#796D64',
  },
  logoBox: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      maxHeight:59
    },
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-between',
    },
  },
  boxRed: {
    width: 132,
    height: 145,
    [theme.breakpoints.down('sm')]: {
      width: 54,
      height: 59,
    },
    background: theme.palette.common.red,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  logoOffwhiteSvg: {
    width: 46,
    [theme.breakpoints.down('sm')]: {
      width: 19,
    },
  },
  logoText: {
    width: 344,
    margin: theme.spacing(0, 3),
    [theme.breakpoints.down('sm')]: {
      width: 182,
      margin: theme.spacing(0, 1),
    },
  },
  footerSection: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: theme.palette.common.white,
    height: 80,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      height: 50,
    },
  },
  logoFooter: {
    height: 42,
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      height: 24,
      marginRight: theme.spacing(1.5),
    },
  },
  textFooter: {
    fontSize: 16,
    marginTop: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(0.5),
      fontSize: 10,
    },
  },
}))

function App() {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }
  return (
    <ThemeProvider theme={theme}>
      <React.Fragment>
        <CssBaseline />
        <Container disableGutters style={{ maxWidth: 1366 }}>
          <AppBar position='static'>
            <Toolbar disableGutters classes={{ regular: classes.toolBar }}>
              <Box className={classes.title}>
                <Box className={classes.logoBox}>
                  <Box
                    className={classes.boxRed}
                    style={{ justifySelf: 'left' }}
                  >
                    <img
                      className={classes.logoOffwhiteSvg}
                      src={LogoOffwhite}
                      alt='logo'
                    />
                  </Box>
                  <img
                    className={classes.logoText}
                    src={LogoText}
                    alt='logo text'
                  />
                  <Box display={{ xs: 'block', md: 'none' }}>
                    <Box
                      className={classes.boxRed}
                      style={{ justifySelf: 'right' }}
                    >
                      <IconButton
                        edge='start'
                        color='primary'
                        aria-label='menu'
                        size='medium'
                        onClick={handleDrawerOpen}
                        className={clsx(
                          classes.menuButton,
                          open && classes.hide
                        )}
                      >
                        <MenuIcon />
                      </IconButton>
                    </Box>
                  </Box>
                </Box>
              </Box>

              <Box
                className={classes.boxAppBarButton}
                display={{ xs: 'none', md: 'block' }}
              >
                <Link
                  to='about'
                  spy={true}
                  smooth={true}
                  offset={-10}
                  duration={500}
                >
                  <Button className={classes.appBarButton} color='inherit'>
                    About
                  </Button>
                </Link>
                <Link
                  to='contact'
                  spy={true}
                  smooth={true}
                  offset={-10}
                  duration={500}
                >
                  <Button className={classes.appBarButton} color='inherit'>
                    Contact us
                  </Button>
                </Link>
              </Box>
            </Toolbar>
          </AppBar>
          <Drawer
            className={classes.drawer}
            variant='persistent'
            anchor='right'
            open={open}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <div className={classes.drawerHeader}>
              <IconButton onClick={handleDrawerClose}>
                {theme.direction === 'ltr' ? (
                  <ChevronLeftIcon />
                ) : (
                  <ChevronRightIcon />
                )}
              </IconButton>
            </div>
            <List>
              <Link
                to='about'
                spy={true}
                smooth={true}
                offset={-10}
                duration={500}
              >
                <ListItem button key={'About'} onClick={handleDrawerClose}>
                  <ListItemText
                    disableTypography={true}
                    className={classes.listItemsText}
                    primary={'About'}
                  />
                </ListItem>
              </Link>
              <Link
                to='contact'
                spy={true}
                smooth={true}
                offset={-10}
                duration={500}
              >
                <ListItem button key={'Contact Us'} onClick={handleDrawerClose}>
                  <ListItemText
                    disableTypography={true}
                    className={classes.listItemsText}
                    primary={'Contact Us'}
                  />
                </ListItem>
              </Link>
            </List>
          </Drawer>
          <main>
            <HeroSection />
            <Divider className={classes.divider} />
            <TextBox id='about' title='ABOUT US'>
              <Typography>
                The team behind Wölfsblade Performance combines years of design
                and development experience with both military and athletic
                credibility.
              </Typography>
              <Typography>
                We create performance gear for for those who like to challenge
                both themselves and the status quo - those who aim to escape the
                pack!
              </Typography>
            </TextBox>
            <CostumerSection />
            <TextBox id='contact' title='CONTACT US'>
              <Typography>
                If you would like to hear more or discuss your requirements for
                custom-made performance and training gear, please use the form
                on the below to get in touch.
              </Typography>
            </TextBox>
            <Divider className={classes.divider} />
            <ContactSection />
            <Box className={classes.footerSection}>
              <img
                className={classes.logoFooter}
                src='img/logos/wolfsblade_darkfur.png'
                alt='Wolfsblade logo'
              />
              <Typography className={classes.textFooter}>
                © WOLFSBLADE LTD
              </Typography>
            </Box>
          </main>
        </Container>
      </React.Fragment>
    </ThemeProvider>
  )
}

export default App
