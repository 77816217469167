import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import theme from './theme'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(() => ({
  box: {
    padding: theme.spacing(1.5, 9),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1.5, 3),
    },
  },
}))

const TextBox = (props) => {
  const classes = useStyles()
  return (
    <Box className={classes.box} id={props.id}>
      <Typography variant='h3'>{props.title}</Typography>
      {props.children}
    </Box>
  )
}
export default TextBox
