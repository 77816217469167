import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import theme from './theme'

import Box from '@material-ui/core/Box'
import svg from './svgs/escape-the-pack.svg'

const useStyles = makeStyles(() => ({
  box: {
    position: 'relative',
    backgroundColor: theme.palette.grey[800],
    color: theme.palette.common.white,
    height: 628,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundImage: `url(img/hero.jpg)`,
    [theme.breakpoints.down('md')]: {
      height: 708,
      backgroundImage: `url(img/hero_m.jpg)`,
    },
  },
  image: {
    width: 670,
    position: 'absolute',
    left: theme.spacing(9),
    top: 300,

    [theme.breakpoints.down('md')]: {
      left: theme.spacing(4),
      top: 549,
    },
    [theme.breakpoints.down('xs')]: {
      width: '90%',
      left: '5%',
      top: 549,
    },
  },
}))

export default function HeroSection() {
  const classes = useStyles()
  return (
    <Box className={classes.box}>
      <img className={classes.image} src={svg} alt='escape the pack' />
    </Box>
  )
}
