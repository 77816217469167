import { createMuiTheme } from '@material-ui/core/styles'
import createBreakpoints from '@material-ui/core/styles/createBreakpoints'

const breakpoints = createBreakpoints({})

export default createMuiTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#E0DBD6',
      // dark: will be calculated from palette.primary.main,
      contrastText: '#796D64',
    },
    secondary: {
      main: '#9D2235',
      contrastText: '#FFFEFD',
    },
    text: {
      primary: '#796D64',
      secondary: '#9D2235',
    },
    common: {
      white: '#FFFEFD',
      red: '#9D2235',
      black: '#796D64',
    },
  },
  typography: {
    fontFamily: ['HelveticaNeue'],
    useNextVariants: true,
    h3: {
      textTransform: 'uppercase',
      fontWeight: 'bold',
      fontSize: 18,
      lineHeight: '24px',
      [breakpoints.down('sm')]: {
        fontSize: 14,
      },
    },
    body1: {
      lineHeight: '24px',
      fontSize: 18,
      [breakpoints.down('sm')]: {
        fontSize: 14,
      },
    },
    body2: {
      lineHeight: '24px',
      fontFamily: ['Petita'],
      letterSpacing: 0.5,
      fontWeight: 'bold',
    },
  },
})
